<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-truck"></i> Assets With Truckers Report</b-card-title>
			<b-card-sub-title>
				Optimize the physical movement of in-transit assets;
				Ensures trade compliance and visibility into daily transportation operations for on-time delivery
			</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="my-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2" visible>
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="10" sm="12" class="mr-4">
										<b-form-group label="Date From">
											<b-form-datepicker name="Date From" v-model="filterBy.dateFrom" locale="en"
												reset-button label-reset-button="Clear" :date-format-options="{
													year: 'numeric',
													month: 'short',
													day: '2-digit',
													weekday: 'short',
												}" :date-disabled-fn="dateFromDisabled" v-validate="'required'" />
											<span v-show="errors.has('Date From')" class="help-block">
												{{ errors.first('Date From') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="10" sm="12" class="mr-4">
										<b-form-group label="Date To">
											<b-form-datepicker name="Date To" v-model="filterBy.dateTo" locale="en"
												reset-button label-reset-button="Clear" :date-format-options="{
													year: 'numeric',
													month: 'short',
													day: '2-digit',
													weekday: 'short',
												}" :date-disabled-fn="dateFromDisabled" v-validate="'required'" />
											<span v-show="errors.has('Date To')" class="help-block">
												{{ errors.first('Date To') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="10" sm="12" class="mr-4">
										<b-form-group label="Truck Company">
											<v-select name="Truck Company" class="style-chooser" label="text" placeholder=" - Please select - "
												:options="allCompanyOptions" :reduce="(company) => company.value"
												v-model="filterBy.truckCompany" v-validate="{
													'selectRequired': !isSuperAdmin
												}">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Truck Company')" class="help-block">
												{{ errors.first('Truck Company') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="10" sm="12" class="mr-4">
										<b-form-group label="Status">
											<b-form-select v-model="filterBy.status" :options="statusOptions"
												class="mr-4" />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="10" sm="12" class="mr-4">
										<b-form-group label="Asset Code">
											<b-form-input id="assetCode" name="Asset Code" type="search" class="numFont"
												v-model="filterBy.assetCode" v-validate="{ regex: /^([a-zA-Z0-9\-])*$/ }" />
											<span v-show="errors.has('Asset Code')" class="help-block">{{
												errors.first('Asset Code')
											}}</span>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="retrieveData">
											Generate
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<div v-if="items.length > 0">
					<div class="btn-table-options">
						<b-row>
							<b-col sm="6" md="3">
								<b-dropdown text=" Select Actions " variant="dark" slot="append">
									<b-dropdown-item>
										<json-excel :data="exportData" :fields="exportFields" type="xls"
											:name="fileName + '.xls'">Export Report in Excel</json-excel>
									</b-dropdown-item>
									<b-dropdown-item>
										<json-excel :data="exportData" :fields="exportFields" type="csv"
											:name="fileName + '.csv'">Export Report to CSV</json-excel>
									</b-dropdown-item>
								</b-dropdown>
							</b-col>
							<b-col sm="6" md="3" offset-md="6" class="my-1 text-md-right">
								<b-input-group prepend="Show" append="/ Page">
									<b-form-select :options="pageOptions" v-model="perPage" />
								</b-input-group>
							</b-col>
						</b-row>
					</div>

					<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
						:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
						:sort-direction="sortDirection" responsive>

						<template v-slot:cell(assetCode)="row">
							<span class="numFont">
								{{ row.item.assetCode }}
							</span>
						</template>

						<template v-slot:cell(daysWithTrucker)="row">
							<span class="numFont">
								{{ row.item.daysWithTrucker }}
							</span>
						</template>

						<template v-slot:cell(dispatchId)="row">
							<span class="numFont">
								{{ row.item.dispatchId }}
							</span>
						</template>

						<template v-slot:cell(status)="row">
							<DispatchRowStatus :row="row" />
						</template>
					</b-table>

					<b-row>
						<b-col md="8" sm="12" class="my-1">
							<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
						</b-col>
						<b-col md="4" sm="12" class="my-1">
							<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
								class="my-0" />
						</b-col>
					</b-row>
				</div>
				<div v-else>
					<b-card> No Results Found. </b-card>
				</div>

			</div>
		</b-card>
	</div>
</template>

<script>
// Components
import DispatchRowStatus from '@/views/transactions/dispatch/DispatchRowStatus';

// Util
import { ValidationUtil } from '@/utils/validationUtil';
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// API
import reportApi from '@/api/reportApi';

// Others
import JsonExcel from 'vue-json-excel';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import _ from 'lodash';

export default {
	name: 'asset-with-truckers-report',
	components: {
		DispatchRowStatus,
		JsonExcel,
		Loading,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'truckCompany',
					sortable: true,
				},
				{
					key: 'plateNo',
					sortable: true,
				},
				{
					key: 'driver',
					sortable: true,
				},
				{
					key: 'assetCode',
					sortable: true,
				},
				{
					key: 'dispatchId',
					label: 'Dispatch ID',
					sortable: true,
				},
				{
					key: 'dateDeployed',
					sortable: true,
				},
				{
					key: 'dateReceived',
					sortable: true,
				},
				{
					key: 'daysWithTrucker',
					sortable: true,
				},
				{
					key: 'source',
					sortable: true,
				},
				{
					key: 'destination',
					sortable: true,
				},
				{
					key: 'status',
					sortable: false,
				},
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			defaultFilterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				truckCompany: config.companyDefaultValue,
				status: null,
				assetCode: '',
			},
			filterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				truckCompany: config.companyDefaultValue,
				status: null,
				assetCode: '',
			},
			prevFilterBy: null,

			allCompanyOptions: [],
			statusOptions: config.dispatchStatus,

			allCompaniesObj: {},
			allAssetTypesObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		dateFrom() {
			const dateTo = moment();
			const dateFrom = dateTo.add(-30, 'days');
			return dateFrom.format('YYYY-MM-DD');
		},
		dateTo() {
			return moment().format('YYYY-MM-DD');
		},

		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			let fields = {};

			_.forEach(this.fields, (field) => {
				fields[field.key] = field.key;
			});

			return fields;
		},

		fileName() {
			let filename = 'AssetsWithTrucker';

			if (this.filterBy.truckCompany && this.filterBy.truckCompany.id) {
				filename += `_${this.filterBy.truckCompany.name}`;
			}

			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return `${filename}_${DateUtil.getDateInMMDDYYYYFormat(currTimeStamp)}`;
		},
	},
	mounted() {
		setTimeout(async () => {
			try {			
				// Filter Access
				if (this.$store.getters.isAccounting
					|| this.$store.getters.isApprover
					|| this.$store.getters.isMaintenance) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// show loading indicator
				this.isLoading = true;

				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.allCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
				this.allAssetTypesObj = { ...this.$store.getters.assetTypes };

				// Set Default Filter Options
				if (!this.isSuperAdmin) {
					this.defaultFilterBy.truckCompany = DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);
				}
				this.defaultFilterBy.dateFrom = this.dateFrom;
				this.defaultFilterBy.dateTo = this.dateTo;
				await this.resetFilters();

			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}

		}, config.timeout);
	},
	methods: {
		async resetFilters() {
			this.items = [];
			this.filterBy = { ...this.defaultFilterBy };

			// reset validation
			this.$validator.reset();
			this.errors.clear();

			await this.retrieveData();
		},

		dateFromDisabled(_ymd, date) {
			return date > new Date();
		},
		isValidAssetCode(assetCode) {
			return _.isEmpty(assetCode) ||
				ValidationUtil.isValidAssetCode(this.allAssetTypesObj, assetCode);
		},
		async validateFilter() {
			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return isValid;
			}

			// Date Range Specific
			if (this.filterBy.dateFrom > this.filterBy.dateTo) {
				this.$toaster.warning(
					'Invalid Date Range. Date From must be less than Date To.'
				);
				isValid = false;
			} else if (
				DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90
			) {
				this.$toaster.warning(
					'Invalid Date Range. Data range is allowed up to 90 days difference.'
				);
				isValid = false;
			}

			else if (!this.isValidAssetCode(this.filterBy.assetCode)) {
				this.$toaster.warning(
					`Invalid Asset Code. "${this.filterBy.assetCode}" doesn't follow any of your asset tagging format.`
				);
				isValid = false;
			}

			return isValid;
		},
		getReportParameters() {
			let filter = { ...this.filterBy };
			filter.companyId = this.loggedUserCompany.id;

			if (this.filterBy.dateFrom) {
				filter.fromTimestamp = DateUtil.startDateTimeStamp(
					new Date(this.filterBy.dateFrom)
				);
			}
			if (this.filterBy.dateTo) {
				filter.toTimestamp = DateUtil.endDateTimeStamp(
					new Date(this.filterBy.dateTo)
				);
			}

			filter.assetCode = this.filterBy.assetCode;

			return filter;
		},
		async retrieveData() {
			let isValid = await this.validateFilter();
			if (!isValid) {
				return;
			}

			// Reset items
			this.items = [];
			// Show loader
			this.isLoading = true;

			try {
				let view = this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY;
				let reportParam = this.getReportParameters();

				let { data } = await reportApi.getAssetsWithTruckersReport(
					reportParam,
					view,
					this.loggedUser.id
				);

				if (data.isSuccess) {
					let items = data.items;

					if (_.isEmpty(items)) {
						this.$toaster.warning('No Results found.');
					} else {
						this.items = items;
						this.totalRows = this.items.length;
					}

					// Mark the prevFilter
					this.prevFilter = { ...this.filterBy };
				} else {
					this.$toaster.error('Error loading data. Please reload the page again.');
				}
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		getNoOfDays(date) {
			return DateUtil.getNoOfDays(date, new Date());
		},
	},
};
</script>